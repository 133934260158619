import api from 'config/Api/Api';
import { formatFormDataBody } from 'utils';

const baseEndpoint = '/amenities-generated';

const baseEndpointImage = ( amenityId ) => `/amenities-generated/${amenityId}/image`;

export default {
  getAmenitiesGenerated( params ) {
    return api.get( baseEndpoint, params );
  },

  getAmenityGenerated( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },

  saveAmenityGenerated( data ) {
    return api.post( baseEndpoint, data );
  },

  updateAmenityGenerated( id, data ) {
    return api.put( `${baseEndpoint}/${id}`, data );
  },

  deleteAmenityGenerated( id ) {
    return api.delete( `${baseEndpoint}/${id}` );
  },
  downloadInventory( id ) {
    return api.post(
      `/properties/all/room-types/all/accommodations/${id}/download-inventory`, {},
    );
  },
  saveImage( amenityId, data ) {
    return api.post( baseEndpointImage( amenityId ), formatFormDataBody( data ) );
  },
};
