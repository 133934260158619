import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import classnames from 'classnames';
import {
  SimpleHeader, Select, DetailCard,
  Intl, Form, FormActionsBtns,
  TextField,
  AsyncSelect,
} from 'components';
import { required } from 'config/InputErrors';

const colMdWidth = 6;

export default React.memo(
  ( {
    data,
    entityId,
    parentPath,
    isLoading,
    onSubmit,
    citiesLoading,
    cities,
    zonesLoading,
    zones,
    setSelectedCity,
    onFetchProperties,
  } ) => (
    <>
      <SimpleHeader parentLinks={[{ name: 'places', href: parentPath }]} />
      <Container
        fluid
        className={classnames( {
          'app-component-loading': isLoading,
        } )}
      >
        <DetailCard
          classNames={{
            header: 'py-3',
          }}
          header={(
            <h3 className="m-0">
              <Intl id={entityId ? 'editPlace' : 'newPlace'} />
              {entityId ? ` ${entityId}` : '' }
            </h3>
 )}
        >
          <Form
            initialValues={data}
            showErrorsInFooter
            onSubmit={onSubmit}
            body={( ) => (
              <>
                <Row>
                  <Col md={{
                    size: colMdWidth,
                  }}
                  >
                    <Row className="mb-3">
                      <Col md={8}>
                        <TextField
                          field="name"
                          label="name"
                          labelClass="font-weight-bold"
                          validate={entityId ? () => null : required}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12}>
                        <TextField
                          field="description"
                          label="description"
                          type="textarea"
                          labelClass="font-weight-bold"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Select
                          field="city"
                          label="city"
                          isLoading={citiesLoading}
                          options={cities}
                          onChange={( cityId ) => {
                            setSelectedCity( cityId );
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        <Select
                          field="zone"
                          label="zone"
                          isLoading={zonesLoading}
                          options={zones}
                        />
                      </Col>
                      <Col md={4}>
                        <AsyncSelect
                          field="propertyId"
                          label="property"
                          placeholder="all"
                          defaultOptions
                          loadOptions={onFetchProperties}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            footer={(
              <Row>
                <Col md={{
                  size: colMdWidth,
                }}
                >
                  <FormActionsBtns cancelTo={parentPath} />
                </Col>
              </Row>
            )}
          />
        </DetailCard>

      </Container>
    </>
  ),
);
