import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PlacesEditView from 'views/Places/Edit';
import SettingsActions from 'store/reducers/Settings';
import { formatDataToSend, handlePromise } from 'utils';
import PlacesService from 'api/Places';
import { history } from 'store';
import CityService from 'api/Settings/City';
import PropertyService from 'api/Property';
import useFetch from 'hooks/useFetch';

const PlacesEdit = ( {
  parentPath, match, toggleErrorAlert,
  toggleInfoAlert, propertyManager,
} ) => {
  const entityId = match.params.id;
  const [loadingData, setLoadingData] = useState( false );

  const [data] = useFetch( {
    promise: () => {
      setLoadingData( true );
      return PlacesService.getItem( entityId );
    },
    format: ( dataToFormat ) => {
      setLoadingData( false );
      return dataToFormat;
    },
    toggleErrorAlert: ( errors ) => {
      setLoadingData( false );
      toggleErrorAlert( errors );
    },
    conditional: !!entityId,
    deps: [entityId],
  } );

  const submitForm = useCallback( async ( formData, form ) => {
    setLoadingData( true );

    const formattedData = formatDataToSend( formData, form );
    delete formattedData.city;

    const [errors, response] = await handlePromise(
      entityId ? PlacesService.updateItem( entityId, formattedData )
        : PlacesService.saveItem( formattedData ),
    );

    setLoadingData( false );
    if ( !response.ok ) {
      return errors;
    }

    toggleInfoAlert( 'dataSaved' );
    return history.push( parentPath );
  }, [entityId, parentPath, toggleInfoAlert, setLoadingData] );

  /** cities,zones ******************* */
  const [selectedCity, setSelectedCity] = useState();

  const [cities, citiesLoading] = useFetch( {
    initialState: [],
    promise: () => CityService.getCities( {
      elementsPerPage: 100,
      propertyManager,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.id,
        name: item.city.name,
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );
  const [zones, zonesLoading] = useFetch( {
    initialState: [],
    promise: () => CityService.getZones( cities.find(
      ( { id } ) => id === selectedCity,
    )?.id, {
      elementsPerPage: 100,
      propertyManager,
    } ),
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const dataFormated = _.map( orignalData, ( item ) => ( {
        id: item.id,
        name: item.name,
      } ) );
      return dataFormated;
    },
    conditional: !!selectedCity,
    toggleErrorAlert,
    deps: [selectedCity],
  } );
  /** END cities, zones ******************* */

  /* Properties */
  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 500,
      keyword: query,
    } ),
    [],
  );
  /** END properties */

  return (
    <PlacesEditView
      data={data}
      entityId={entityId}
      parentPath={parentPath}
      isLoading={loadingData}
      onSubmit={submitForm}
      citiesLoading={citiesLoading}
      cities={cities}
      zonesLoading={zonesLoading}
      zones={zones}
      setSelectedCity={setSelectedCity}
      onFetchProperties={fetchProperties}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager.id', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( mapStateToProps, mapDispatchToProps )( PlacesEdit );
